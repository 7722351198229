import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import logoSrc from '../assets/images/logo.svg';

const LogoStyles = styled(Link)`
  img {
    height: 100px;
  }
  header & img {
    height: 60px;
  }
  @media (max-width: 767px) {
    header & img {
      height: 40px;
    }
    img {
      height: 60px;
    }
  }
`;

export default function Logo() {
  return (
    <LogoStyles to="/">
      <img src={logoSrc} alt="Altech logo" />
    </LogoStyles>
  );
}
