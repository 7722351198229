import { createGlobalStyle } from 'styled-components';

import RS from '../assets/fonts/RobotoSlab700.ttf';

const Typography = createGlobalStyle`

  @font-face {
    font-family: 'RobotoSlab';
    src: url(${RS});
  }

  html {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: 'RobotoSlab';
    font-weight: 700;
    margin: 0;
    text-align: left;
  }

  h1 {
    font-size: 50px;
    line-height: 60px;
  }

  h2 {
    font-size: 42px;
    line-height: 50px;
  }

  h3 {
    font-size: 36px;
    line-height: 40px;
  }

  h4 {
    font-size: 30px;
    line-height: 34px;
  }

  .tilt {
    transform: rotate(-2deg);
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 30px;
      line-height: 38px;
    }
    h2 {
      font-size: 26px;
      line-height: 34px;
    }
    h3 {
      font-size: 22px;
      line-height: 30px;
    }
    h4 {
      font-size: 20px;
      line-height: 28px;
    }
  }

`;

export default Typography;
