import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #222;
    --white: #fff;
    --grey: #f2f2f2;
    --green: #76BC21;
  }
  * {
    box-sizing: border-box;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */

  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  img {
    max-width: 100%;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
    :active,
    :focus,
    :hover,
    :visited {
      text-decoration: none;
      outline: none;
    }
  }

  p {
    margin: 0;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

  .container {
    width: 900px;
    max-width: calc(100% - 80px);
    margin: 0 auto;
  }
  
  textarea:focus, input:focus{
    outline: none;
  }

  button {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  figure {
    margin: 0;
  }

  @media (max-width: 767px) {
    .container {
      max-width: calc(100% - 40px);
    }
  }

`;

export default GlobalStyles;
